import React from 'react'
import './Junta.css'
import { Card, Row, Col, Alert, Figure } from 'react-bootstrap'


const Junta = () => {
    return (
        <div className='Junta'>
        <Row>
            <Col>
                <br />
                <Alert variant='danger'>
                    La Junta Directiva - Pensones i càrrecs
                </Alert>
                <Figure>
                        <Figure.Image
                            width={100}
                            height={50}
                            alt="171x180"
                            src="img/Logop.png" />
                </Figure>
            </Col>
            <Row xs={1} md={3} lg={4} className="g-1">

                <Col>
                <Card style={{ width: { xs: "20rem", md: '30rem' } }}>
                    <Card.Header>President</Card.Header>
                    <Card.Body>
                    <Card.Img variant="top" src="img/JosepClusella.png" height="auto" width="200" />                
                        <Card.Img></Card.Img>
                        <Card.Text>Josep Clusella i fabrés</Card.Text>
                        <Card.Footer>
                                <h3 className="text-muted">Gestió del club global</h3>
                                <h4 className="text-muted"><a href = "mailto:presidencia@vicvoleicallis.cat">presidencia@vicvoleicallis.cat</a></h4>
                        </Card.Footer>
                    </Card.Body>

                </Card><br/>
                </Col>
                
                <Col>
                    <Card style={{ width: { xs: "20rem", md: '30rem' }  }}>
                        <Card.Header>Vice-president</Card.Header>
                        <Card.Body>
                        <Card.Img variant="top" src="img/ManelFernandez.png"  height="auto" width="200" />                
                            <Card.Img></Card.Img>
                            <Card.Text>Manel Fernández i Carmona</Card.Text>
                            <Card.Footer>
                                <h3 className="text-muted">Vicepresidència i Gestions esportives</h3>
                                <h4 className="text-muted"><a href = "mailto:vicepresidencia@vicvoleicallis.cat">vicepresidencia@vicvoleicallis.cat</a></h4>
                        </Card.Footer>
                        </Card.Body>
                    </Card><br/>        
                </Col>

                <Col>
                    <Card style={{ width: { xs: "20rem", md: '30rem' }  }}>
                        <Card.Header>Tresoreria</Card.Header>
                        <Card.Body>
                        <Card.Img variant="top" src="img/MariaPuig.png"  height="auto" width="200" />                
                            <Card.Img></Card.Img>
                            <Card.Text>Maria Puig i Sala</Card.Text>
                            <Card.Footer>
                                <h3 className="text-muted">Comptabilitat, pagaments</h3>
                                <h4 className="text-muted"><a href = "mailto:tresoreria@vicvoleicallis.cat">tresoreria@vicvoleicallis.cat</a></h4>
                        </Card.Footer>
                        </Card.Body>
                    </Card><br/>        
                </Col>


                <Col>
                    <Card style={{ width: { xs: "20rem", md: '30rem' } }}>
                        <Card.Header>Secretaria</Card.Header>
                        <Card.Body>
                        <Card.Img variant="top" src="img/DolorsFerrer.png"  height="auto" width="200" />                
                            <Card.Img></Card.Img>
                            <Card.Text>Dolors Ferrer i Bautista</Card.Text>
                            <Card.Footer>
                                <h3 className="text-muted">Actes, subvencions, burocràcia</h3>
                                <h4 className="text-muted"><a href = "mailto:secretaria@vicvoleicallis.cat">secretaria@vicvoleicallis.cat</a></h4>
                        </Card.Footer>
                        </Card.Body>
                    </Card><br/>        
                </Col>

                <Col>
                    <Card style={{ width: { xs: "20rem", md: '30rem' }  }}>
                        <Card.Header>Vocal</Card.Header>
                        <Card.Body>
                        <Card.Img variant="top" src="img/EvaCanal.png"  height="auto" width="200" />                
                            <Card.Img></Card.Img>
                            <Card.Text>Eva Canal i Buixó</Card.Text>
                            <Card.Footer>
                                <h3 className="text-muted">Equipaments esportius</h3>
                                <h4 className="text-muted"><a href = "mailto:equipacio@vicvoleicallis.cat">equipacio@vicvoleicallis.cat</a></h4>
                            </Card.Footer>
                        </Card.Body>
                    </Card><br/>        
                </Col>

                <Col>
                    <Card style={{ width: { xs: "20rem", md: '30rem' }  }}>
                        <Card.Header>Vocal</Card.Header>
                        <Card.Body>
                        <Card.Img variant="top" src="img/JustVivas.png"  height="auto" width="200" />                
                            <Card.Img></Card.Img>
                            <Card.Text>Just Vivas i Arias</Card.Text>
                            <Card.Footer>
                                <h3 className="text-muted">Manteniments</h3>
                                <h4 className="text-muted"><a href = "mailto:manteniment@vicvoleicallis.cat">manteniment@vicvoleicallis.cat</a></h4>
                            </Card.Footer>
                        </Card.Body>
                    </Card><br/>        
                </Col>

                <Col>
                    <Card style={{ width: { xs: "20rem", md: '30rem' }  }}>
                        <Card.Header>Vocal</Card.Header>
                        <Card.Body>
                        <Card.Img variant="top" src="img/RogerRoig.png"  height="auto" width="200" />                
                            <Card.Img></Card.Img>
                            <Card.Text>Roger Roig i Redondo</Card.Text>
                            <Card.Footer>
                                <h3 className="text-muted">Sanitat, gestió de delegats, manteniments</h3>
                                <h4 className="text-muted"><a href = "mailto:tecnic@vicvoleicallis.cat">medic_delegats@vicvoleicallis.cat</a></h4>
                            </Card.Footer>
                        </Card.Body>
                    </Card><br/>        
                </Col>

                <Col>
                    <Card style={{ width: { xs: "20rem", md: '30rem' }  }}>
                        <Card.Header>Vocal</Card.Header>
                        <Card.Body>
                        <Card.Img variant="top" src="img/JosepTorras.png"  height="auto" width="200" />                
                            <Card.Img></Card.Img>
                            <Card.Text>Josep Torras i Erra </Card.Text>
                            <Card.Footer>
                                <h3 className="text-muted">Tecnològiques, web, xxss, loteria</h3>
                                <h4 className="text-muted"><a href = "mailto:tecnic@vicvoleicallis.cat">tecnic@vicvoleicallis.cat</a></h4>
                            </Card.Footer>
                        </Card.Body>
                    </Card><br/>        
                </Col>

                <Col>
                    <Card style={{ width: { xs: "20rem", md: '30rem' }  }}>
                        <Card.Header>Vocal</Card.Header>
                        <Card.Body>
                        <Card.Img variant="top" src="img/FerranPuigcercos.png"  height="auto" width="200" />                
                            <Card.Img></Card.Img>
                            <Card.Text>Ferran Puigcercós i Sanahuja</Card.Text>
                            <Card.Footer>
                                <h3 className="text-muted">Gestió esportiva</h3>
                                <h4 className="text-muted"><a href = "mailto:tecnic@vicvoleicallis.cat">gestioesportiva@vicvoleicallis.cat</a></h4>
                            </Card.Footer>
                        </Card.Body>
                    </Card><br/>        
                </Col>
                <Col>
                    <Card style={{ width: { xs: "20rem", md: '30rem' }  }}>
                        <Card.Header>Vocal</Card.Header>
                        <Card.Body>
                        <Card.Img variant="top" src="img/ManelOchoa.jpg"  height="auto" width="200" />                
                            <Card.Img></Card.Img>
                            <Card.Text>Manel Ochoai i Jufré</Card.Text>
                            <Card.Footer>
                                <h3 className="text-muted">Coordinació esportiva</h3>                                
                            </Card.Footer>
                        </Card.Body>
                    </Card><br/>        
                </Col>                
            </Row>
            <Row>
            <Col>
                <br />
                <Alert variant='danger'>
                    Col·laboradors - Ajudants per tasques concretes
                </Alert>
            </Col>
            </Row>
            <Row>{/*
                <Col>
                    <Card style={{ width: { xs: "20rem", md: '30rem' }  }}>
                        <Card.Header>Col·laborador</Card.Header>
                        <Card.Body>
                            <Card.Text>Jordi Guitart</Card.Text>
                            <Card.Footer>
                                <h3 className="text-muted">Lesions, temes mèdics</h3>
                                <h4 className="text-muted"><a href = "mailto:medic@vicvoleicallis.cat">medic@vicvoleicallis.cat</a></h4>
                            </Card.Footer>
                        </Card.Body>
                    </Card><br/>        
    </Col> */}
                <Col>
                    <Card style={{ width: { xs: "20rem", md: '30rem' }  }}>
                        <Card.Header>Col·laborador</Card.Header>
                        <Card.Body>
                            <Card.Text>Carles Camprubi</Card.Text>
                            <Card.Footer>
                                <h3 className="text-muted">Promoció</h3>
                                <h4 className="text-muted"><a href = "mailto:promocio@vicvoleicallis.cat">promocio@vicvoleicallis.cat</a></h4>
                            </Card.Footer>
                        </Card.Body>
                    </Card><br/>        
                </Col>
            </Row>
        </Row>
</div>
    );
};

export default Junta;